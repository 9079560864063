.Toastify__toast-container--top-right {
  top: 4em !important;
  z-index: 10001 !important;
}

.jodit-wysiwyg {
  height: 250px !important;
}

.jodit-add-new-line {
  display: none !important;
}

.jodit_sticky>.jodit-toolbar__box {
  position: relative !important;
}
.table > :not(caption) > * > * {
  background-color: transparent !important;
} 

/* .editor-table-fix tr{
  background-color: transparent !important;
}

tr {
  background-color: transparent !important;
} */

.ck-editor__editable {
  min-height: 250px !important;
}

.editor-table-fix{
  margin: 0px 3px 0px 3px !important;
}